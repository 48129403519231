<template>
  <div>
    <div>to see the definition:</div>
    <br />
    <a :href="constructURL" target="_blank">{{ constructURL }} </a>
  </div>
</template>

<script>
export default {
  props: {
    word: {
      type: String,
      required: true
    }
  },
  computed: {
    constructURL() {
      return "https://www.dictionary.com/browse/" + this.word;
    }
  }
};
</script>

<style scoped></style>
